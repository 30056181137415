/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 */

/* Core CSS required for Ionic components to work properly */
/* stylelint-disable-next-line scss/at-import-partial-extension */
@import "app/css/gr.bundle.scss";
@import "~@gyselroth/ionic-angular/css/ionic.bundle.css";
@import "~typeface-ubuntu/index.css";
